import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';


const Retrospect = ({ pageContext }) => {
    const [position, setPosition] = useState('bottom');
    const { node, next, previous } = pageContext;
    const retrospect = node;
    const images = [];
    useEffect(() => {
        if (typeof window !== `undefined`) {
            if (window.innerWidth < 768) {
                setPosition('bottom');
            }
        }
    });
    if (retrospect.type !== 'iframe') {
        retrospect.media.map(item => {
            images.push({
                original: item.img_src.publicURL,
                thumbnail: item.img_src.publicURL
            })
        })
    }
    const nextProject = next && (
        <Link to={`/retrospect/${next.slug}`}>
            <div className="clearfix bshadow0 pbs" style={{ cursor: 'pointer' }}>
                <span className="mls">{next.title}</span>
                <GrFormNext />
            </div>
        </Link>
    )
    const previousProject = previous && (
        <Link to={`/retrospect/${previous.slug}`}>
            <div className="clearfix bshadow0 pbs" style={{ cursor: 'pointer' }}>
                <GrFormPrevious />
                <span className="mls">{previous.title}</span>
            </div>
        </Link>
    )

    return (
        <Layout pageInfo={{ pageName: 'retrospects' }}>
            <Helmet>
                <title>roelheremans | {retrospect.title}</title>
            </Helmet>
            <div className="container pt-5" id="myContainer">
                <div className="site-section">
                    <div className="d-flex justify-content-center">
                        <h2 className="display-8">{retrospect.title}&nbsp;{retrospect.year}</h2>
                    </div>
                </div>

                {
                    retrospect.type === "iframe" ?
                        <div className="row">
                            {
                                retrospect.media.map((item, index) => {
                                    return <div key={index} className="col-md-4 mb-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe title={item.title} className="embed-responsive-item" src={item.alt} allowFullScreen />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        :
                        images ? <ImageGallery items={images} showPlayButton={false} thumbnailPosition={position} showNav={false} /> : null

                    // <div className="galleryRetrospect">
                    //     {
                    //         retrospect.media.map((item, index) => {
                    //             return <img src={item.img_src.publicURL} alt={item.alt} key={item.id} className="img-fluid" />
                    //         })
                    //     }
                    // </div>
                }


                <div className="row pt-lg-5">
                    <div className="col-md-12 pt-5">
                        <div className="aboutPara px-lg-5">
                            <Markdown source={retrospect.description} escapeHtml={false} className="text-center"></Markdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer prevNext">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <div>{previousProject}</div>
                                <div className="ml-auto">{nextProject}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Retrospect;